import { isEcommerce } from '@/utils/Common/Booleans';
import PropTypes from 'prop-types';
import React from 'react';

// import components
import OmnibusPricing from '@/components/molecules/OmnibusPricing';

const PriceBox = ({
  omnibusScenario,
  oldPriceValue,
  oldPrice,
  actualPriceValue,
  actualPrice,
  lastLowerPriceValue,
  lastLowerPrice,
  isRegistered,
  isReview,
  billingTimeDescription,
}) => {
  if (isRegistered || isReview) return null;

  return (
    isEcommerce && (
      <div className='prices'>
        <OmnibusPricing
          omnibusScenario={omnibusScenario}
          mainPriceValue={actualPriceValue}
          mainPriceFormattedValue={actualPrice}
          oldPriceValue={oldPriceValue}
          oldPriceFormattedValue={oldPrice}
          lastLowerPriceValue={lastLowerPriceValue}
          lastLowerPriceFormattedValue={lastLowerPrice}
          billingTimeDescription={billingTimeDescription}
        />
      </div>
    )
  );
};

PriceBox.propTypes = {
  omnibusScenario: PropTypes.number,
  oldPriceValue: PropTypes.number,
  oldPrice: PropTypes.string,
  actualPriceValue: PropTypes.number,
  actualPrice: PropTypes.string,
  lastLowerPriceValue: PropTypes.number,
  lastLowerPrice: PropTypes.string,
  isRegistered: PropTypes.bool,
  isReview: PropTypes.bool,
  billingTimeDescription: PropTypes.string,
};

PriceBox.defaultProps = {
  omnibusScenario: 0,
  oldPriceValue: null,
  oldPrice: null,
  actualPriceValue: null,
  actualPrice: null,
  lastLowerPriceValue: null,
  lastLowerPrice: null,
  billingTimeDescription: null,
  isRegistered: false,
  isReview: false,
};

export default PriceBox;
