import CONST from '@/utils/Constants/General';
import _isEmpty from 'lodash/isEmpty';
import { productImageByFormat } from './ProductImage';
// this method parse and transform cart entries to manage possible use cases related to virtual bundle and subscriptions
// it creates a new structure of entries as follows:
// bundles: contains entries related to the bundle
// entries: contains the default entries.
// subscriptions: contains entries related to the subscriptions
export const transformCartEntries = cart => {
  const bundles = [];
  const standardEntries = [];
  const subscriptions = [];
  const summaryEntries = []; // array used in minicart and checkout summary

  const emptyOutput = {
    hasEntries: false,
    totalEntries: 0,
    bundles,
    standardEntries,
    subscriptions,
    summaryEntries,
  };

  try {
    if (!cart?.entries) return emptyOutput; // no entries, do nothing

    // these Sets are used to track only once the bundles and subscriptions to avoid rendering duplicate entries.
    const bundleRootEntryGroupsSet = new Set();
    const subscriptionRootEntryGroupsSet = new Set();

    cart.entries.forEach(entry => {
      // CASE 1: default entry, standard product
      if (!entry.rootEntryGroup) standardEntries.push(entry);

      // CASE 2: entry is a bundle
      if (
        entry.rootEntryGroup &&
        !entry.isSubscription &&
        !bundleRootEntryGroupsSet.has(entry.rootEntryGroup)
      ) {
        const bundle = cart.rootGroups.find(
          rootGroup => rootGroup.groupNumber === entry.rootEntryGroup,
        );
        if (!bundle) throw new Error('no bundle found');

        bundleRootEntryGroupsSet.add(entry.rootEntryGroup);
        bundles.push({
          id: `${bundle.label}-${entry.rootEntryGroup}`,
          entryGroupNumber: entry.rootEntryGroup,
          name: bundle.label,
          price: bundle.rootBundleTemplate.bundlePriceData,
          oldPrice: bundle.totalItemsPrice,
          discountedPrice: bundle.discountedAmount,
          products: bundle.children.map(child => ({
            code: child.rootBundleTemplate.products[0].code,
            name: child.rootBundleTemplate.products[0].name,
            price: child.rootBundleTemplate.bundlePriceData,
            images: child.orderEntries[0].productImages || [
              child.orderEntries[0].productImage,
            ],
            vat: child.vat,
            vatratio: child.vatRatio,
          })),
        });
      }

      // CASE 3: entry is a subscription
      if (
        entry.rootEntryGroup &&
        entry.isSubscription &&
        !subscriptionRootEntryGroupsSet.has(entry.rootEntryGroup)
      ) {
        const subscription = cart.rootGroups.find(
          rootGroup => rootGroup.groupNumber === entry.rootEntryGroup,
        );
        if (!subscription) throw new Error('no subscription found');

        subscriptionRootEntryGroupsSet.add(entry.rootEntryGroup);
        entry.rootGroup = subscription;
        subscriptions.push(entry);
      }
    });

    // preapre entries for order summary and minicart
    // start from bundles
    bundles.forEach(bundle => {
      bundle.products.forEach(product => {
        const productImage = productImageByFormat(
          product.images,
          CONST.PRODUCT.IMAGE.FORMAT.THUMBNAIL,
        );
        summaryEntries.push({
          code: product.code,
          name: product.name,
          price: product.price,
          image: { url: productImage?.xs?.url, altText: productImage?.altText },
          quantity: 1,
        });
      });
    });

    standardEntries.forEach(entry => {
      const productConfigurable =
        entry?.configurableProductDataConfigurationId &&
        entry?.configurableProductDataSnapshot;
      const thumbnailBASE64 = entry?.configurableProductDataSnapshot;
      let cartIcon = entry.productImage;
      if (!_isEmpty(entry.productImages)) {
        cartIcon = productImageByFormat(
          entry.productImages,
          CONST.PRODUCT.IMAGE.FORMAT.CART_ICON,
        );
      }
      const productImageUrl = productConfigurable
        ? thumbnailBASE64
        : cartIcon?.s?.url;
      const productImageAlt = productConfigurable
        ? thumbnailBASE64
        : cartIcon?.altText;
      summaryEntries.push({
        code: entry.productCode,
        name: entry.productName,
        price: {
          value: entry.actualPriceValue,
          formattedValue: entry.actualPrice,
        },
        oldPrice: {
          value: entry.oldPriceValue,
          formattedValue: entry.oldPrice,
        },
        image: { url: productImageUrl, altText: productImageAlt },
        quantity: entry.quantity,
      });
    });

    subscriptions.forEach(subscription => {
      const hasCartIcon = subscription.rootGroup?.rootBundleTemplate?.images.find(
        image => image.format === CONST.PRODUCT.IMAGE.FORMAT.CART_ICON,
      );
      const iconFormat = hasCartIcon
        ? CONST.PRODUCT.IMAGE.FORMAT.CART_ICON
        : CONST.PRODUCT.IMAGE.FORMAT.THUMBNAIL;
      const productImageData = productImageByFormat(
        subscription.rootGroup?.rootBundleTemplate?.images,
        iconFormat,
      );
      summaryEntries.push({
        code: subscription.productCode,
        name:
          subscription.rootGroup?.rootBundleTemplate?.rootBundleTemplateName,
        image: {
          url: productImageData?.url,
          altText: productImageData?.altText,
        },
        quantity: 1,
        htmlDescription:
          subscription.rootGroup?.rootBundleTemplate?.htmlDescription,
        description: subscription.rootGroup?.rootBundleTemplate?.description,
      });
    });

    return {
      hasEntries: true,
      totalEntries: cart.entries.length,
      hasBundles: bundles.length > 0,
      bundles,
      hasStandardEntries: standardEntries.length > 0,
      standardEntries,
      hasSubscriptions: subscriptions.length > 0,
      subscriptions,
      summaryEntries,
    };
  } catch (e) {
    console.error('transformCartEntries // Something went wrong', e);
    return emptyOutput;
  }
};

export const parse = cart => {
  const newCartEntries = [];

  const existingRootEntryGroups = new Set();

  if (cart && cart.entries) {
    cart.entries.forEach(entry => {
      if (entry.rootEntryGroup) {
        if (!existingRootEntryGroups.has(entry.rootEntryGroup)) {
          const childrens = cart.entries.filter(
            childEntry => childEntry.rootEntryGroup === entry.rootEntryGroup,
          );
          if (childrens.length > 0) {
            entry.bundleEntries = childrens;
          }
          if (cart.rootGroups && cart.rootGroups.length > 0) {
            entry.rootGroup = cart.rootGroups.find(
              e => e.groupNumber === entry.rootEntryGroup,
            );
          }
          newCartEntries.push(entry);
          existingRootEntryGroups.add(entry.rootEntryGroup);
        }
      } else {
        newCartEntries.push(entry); // entry default
      }
    });
  }

  return newCartEntries;
};

export default {
  parse,
};
