// function to check if some adblocker block the gtm integration
import GTM from '@/utils/Controllers/GTM/Constants';
import _assign from 'lodash/assign';
import { isTrue } from '@/utils/Common/Booleans';
import { parseJSON } from '@/utils/Common/Utils';
import EVENTS from '@/utils/Constants/Events';

export const hasGtmActive = () => window.google_tag_manager;

export const push = obj => {
  try {
    if (hasGtmActive()) {
      window.dataLayer.push(obj);
    } else {
      window.addEventListener(EVENTS.GTM.LOADED, () => {
        window.dataLayer.push(obj);
      });
    }
  } catch (e) {
    console.error('GTM-Error: ', e);
  }
};

export const getProductsGtmInfo = (entries, quantity = null) =>
  entries.map(entry => {
    const dimension2 = entry.hasPromotions;
    const price =
      entry.finalUnitPriceWithoutTax?.value || entry.oneTimePriceData?.value;
    return _assign(
      JSON.parse(entry.gtmInfo),
      { quantity: quantity || entry.quantity },
      { price },
      { dimension2 },
    );
  });

export const getProductsGtmInfoForListing = (
  gtmInputContainer,
  appendListName = false,
) => {
  const {
    productPrice,
    productDimension1,
    productDimension2,
    productDimension3,
    productPosition,
    listName,
  } = gtmInputContainer.dataset;

  const productInfo = _assign(
    parseJSON(gtmInputContainer.value),
    productPrice && { price: productPrice },
    productDimension1 && {
      dimension1:
        productDimension1 === 'outOfStock'
          ? GTM.EVENT.PRODUCT.OUT_STOCK
          : GTM.EVENT.PRODUCT.IN_STOCK,
    },
    productDimension2 && {
      dimension2: isTrue(productDimension2)
        ? GTM.EVENT.PRODUCT.REDUCED
        : GTM.EVENT.PRODUCT.FULL_PRICE,
    },
    productDimension3 && { dimension3: productDimension3 },
    productPosition && { position: productPosition },
    appendListName && { list: listName },
  );
  return productInfo;
};

export const updateProductsPositionForGTMListing = () => {
  const productsGtmContainer = document.querySelectorAll(
    '.js-gtm-product-impression',
  );
  productsGtmContainer.forEach((container, i) => {
    container.dataset.productPosition = i + 1;
  });
};
