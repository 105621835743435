import React, { useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useOnClickOutside from '@/hooks/useOnClickOutside';

const ManualsDropdownItem = ({ language, onClick }) => (
  <button
    type='button'
    onClick={onClick}
    className='dln-paperless-product__manuals-selector-item'
  >
    {language.name}
  </button>
);

const ManualsDownloadItem = ({ file }) => {
  const { t } = useTranslation();
  const filename = useMemo(() => {
    const part1 = t(`paperless:paperless.${file.name}`);
    const part2 = file.isMultiLanguage
      ? ` (${t('paperless:paperless.manual.type.multylanguage')})`
      : '';
    return part1 + part2;
  }, [file.name, file.isMultiLanguage]);

  return (
    <a
      key={file.downloadUrl}
      href={file.downloadUrl}
      className='dln-paperless-product__manuals-download-item'
      target='_blank'
      rel='noopener noreferrer'
      data-filename={filename}
    >
      <div className='dln-paperless-product__manuals-download-pdf-icon' />
      <p className='dln-paperless-product__manuals-download-info'>
        <span className='dln-paperless-product__manuals-download-name'>
          {filename}
        </span>
        <span className='dln-paperless-product__manuals-download-size'>
          {(file.size / 1000000).toFixed(2)} MB
        </span>
      </p>
      <div className='dln-paperless-product__manuals-download-icon' />
    </a>
  );
};

const PaperlessManualsApp = ({ files }) => {
  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const ref = useRef();
  useOnClickOutside(ref, () => setOpen(false));
  const handleToggle = () => {
    setOpen(!isOpen);
  };

  const getFilesWithSelectedLanguage = language => {
    const filesWithSelectedLanguage = files.filter(
      file =>
        file.languages.find(lang => lang.isoCode === language.isoCode) ||
        file.isMultiLanguage,
    );

    return filesWithSelectedLanguage;
  };

  const handleLanguageChange = language => {
    setSelectedLanguage(language);
    setOpen(false);

    const filesWithSelectedLanguage = getFilesWithSelectedLanguage(language);
    setSelectedFiles(filesWithSelectedLanguage);
  };

  const allLanguages = useMemo(() => {
    const languages = [];
    files.forEach(file => {
      file.languages.forEach(language => {
        if (!languages.find(lang => lang.isoCode === language.isoCode)) {
          languages.push(language);
        }
      });
    });
    languages.sort((a, b) => a.name.localeCompare(b.name));
    return languages;
  }, [files]);

  useEffect(() => {
    const storeIsoCode = DLG.config.currentLanguageIsocode.toLowerCase();
    const fallbackIsoCode = DLG.config.currentLanguageIsocode
      .toLowerCase()
      .slice(0, 2); // check without the country local
    const secondaryFallbackIsoCode = 'en';

    const storeLanguage = allLanguages.find(
      language => language.isoCode.toLowerCase() === storeIsoCode,
    );
    const fallbackLanguage = allLanguages.find(
      language => language.isoCode.toLowerCase() === fallbackIsoCode,
    );
    const secondaryFallbackLanguage = allLanguages.find(
      language => language.isoCode.toLowerCase() === secondaryFallbackIsoCode,
    );

    const preselectedLanguage =
      storeLanguage ||
      fallbackLanguage ||
      secondaryFallbackLanguage ||
      allLanguages[0];

    setSelectedLanguage(preselectedLanguage);
    const filesWithSelectedLanguage = getFilesWithSelectedLanguage(
      preselectedLanguage,
    );
    setSelectedFiles(filesWithSelectedLanguage);
  }, []);

  return (
    <>
      <div className='dln-paperless-product__manuals-header'>
        <div className='dln-paperless-product__manuals-title'>
          <span>{t('paperless:paperless.manuals.title')}</span>
        </div>

        <div className='dln-paperless-product__manuals-selector' ref={ref}>
          <div className='dln-paperless-product__manuals-selector-label'>
            <span>{t('paperless:paperless.manuals.selector.label')}</span>
          </div>
          <button
            className={`dln-paperless-product__manuals-selector-button${
              isOpen ? ' --open' : ''
            }`}
            type='button'
            onClick={handleToggle}
          >
            <span>{selectedLanguage?.isoCode.toUpperCase()}</span>
            <i
              className='dln-icn dln-icn--iconfont dln-icn--arrow-top dln-icn--k'
              data-glyph='k'
            >
              k
            </i>
          </button>

          {isOpen && (
            <div className='dln-paperless-product__manuals-selector-items'>
              {allLanguages.map(language => (
                <ManualsDropdownItem
                  key={language.isoCode}
                  language={language}
                  onClick={() => handleLanguageChange(language)}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      <div className='dln-paperless-product__manuals-download'>
        {selectedFiles.length > 0 &&
          selectedFiles.map(file => (
            <ManualsDownloadItem key={file.downloadUrl} file={file} />
          ))}
      </div>
    </>
  );
};

ManualsDropdownItem.propTypes = {
  language: PropTypes.shape({
    isoCode: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

ManualsDownloadItem.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string.isRequired,
    downloadUrl: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    isMultiLanguage: PropTypes.bool.isRequired,
  }).isRequired,
};

PaperlessManualsApp.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      size: PropTypes.number.isRequired,
      languages: PropTypes.arrayOf(
        PropTypes.shape({
          isoCode: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        }),
      ).isRequired,
      downloadUrl: PropTypes.string.isRequired,
      isMultiLanguage: PropTypes.bool.isRequired,
    }),
  ).isRequired,
};

export default PaperlessManualsApp;
