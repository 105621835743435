import { POST } from '@/api'; // Can't use axios utils since BASE_URL is different!

export default async ({ payload, config, successCB, failCB }) => {
  try {
    const urlWithParams = `/cart/addBundle?bundleTemplate=${payload.templateId}&productCodeForBundle=${payload.productCode}`;
    const { data } = await POST(urlWithParams, {}, config);

    return (
      (typeof successCB === 'function' &&
        successCB(data, payload.templateId)) ||
      data
    );
  } catch (e) {
    return (typeof failCB === 'function' && failCB(e)) || e;
  }
};
