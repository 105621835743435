export default {
  EVENT: {
    NEWSLETTER: 'newsletter',
    RESERVED_AREA: 'reservedArea',
    OTHER_REGISTRATIONS: 'otherRegistrations',
    CONTACT_US: 'contactUs',
    VIDEO: 'video',
    DOWNLOAD: 'download',
    CHAT: 'chat',
    SERVICE_LOCATOR: 'serviceLocator',
    INTERNAL_SEARCH: 'internalSearch',
    WISHLIST: {
      IN: 'wishlist-in',
      OUT: 'wishlist-out',
    },
    PRODUCT_ZOOM: 'productZoom',
    WHERE_TO_BUY: 'where-to-buy',
    STOCK_ALERT: 'stock-alert',
    RATING: 'rating',
    SAVE_RECIPE: 'saveRecipe',
    // ###########
    CATEGORY: {
      LOYALTY: 'loyalty',
      ENGAGEMENT: 'engagement',
      AFTER_SALES: 'afterSales',
      ECOMMERCE: 'ecommerce',
      OFFICINA: 'officina',
    },
    // ###########
    ACTION: {
      RESERVED_AREA: 'reservedArea',
      NEWSLETTER: 'newsletter',
      OTHER_REGISTRATIONS: 'otherRegistrations',
      CONTACT_US: 'contactUs',
      VIDEO: {
        PLAY: 'video-play',
        PAUSE: 'video-pause',
        COMPLETE: 'video-complete',
      },
      DOWNLOAD: 'download',
      CHAT: 'chat',
      SERVICE_LOCATOR: 'serviceLocator',
      INTERNAL_SEARCH_RESULT: 'internalSearch-result',
      INTERNAL_SEARCH_PRODUCT_CLICK: 'internalSearch-productClick',
      RATING: 'rating',
      COMPARISON_TOOL_LISTING: 'comparisonToolListing',
      COMPARISON_TOOL_PRODUCT_DETAIL: 'comparisonToolProductpage',
      PRODUCT_REGISTRATION: 'productRegistration',
      KENWOOD: {
        CONFIGURATOR: {
          FUNNEL: journey => `configurator-${journey}-funnel`,
          WANNABE_HELP_ME_CHOOSE: 'configurator-wannabe-help_me_choose',
          OWNER_ADAPTER_ADD: 'configurator-owner-adapter_add',
          PRODUCT_SELECT: journey => `configurator-${journey}-product_select`,
          SELECT_COLOR: journey => `configurator-${journey}-select_color`,
          PRODUCT_CHANGE: journey => `configurator-${journey}-product_change`,
          ACCESSORY_ADD: journey => `configurator-${journey}-accessory_add`,
          ACCESSORY_REMOVE: journey =>
            `configurator-${journey}-accessory_remove`,
          ACCESSORY_ADD_MORE: journey =>
            `configurator-${journey}-accessory_add_more`,
          OWNER_MODAL: 'configurator-owner-modal',
          MODAL_PROMO_ALERT: journey => `configurator-${journey}-modal`,
          PDP_CTA: 'configurator-PDP-configure',
        },
      },
      PAPERLESS: {
        REGISTER_PRODUCT: 'paperless-register_product',
        DOWNLOAD_MANUALS: 'paperless-download_manuals',
        COFFEE_LOUNGE: 'paperless-visit_coffee_lounge',
        CLICK_SUPPORT: 'paperles-click_support',
        FAQ: {
          SINGLE: 'paperless-faq_single',
          ALL: 'paperless-faq_all',
        },
      },
      OFFICINA: {
        HERO_CLICK: 'hero_button_click',
      },
    },
    // ###########
    LABEL: {
      CTA: 'cta',
      SIGN_UP: 'signUp',
      PREDOUBLEOPTIN_SIGN_UP: 'predoubleoptin-signUp',
      SENT: 'sent',
      LOGIN: 'login',
      OPEN: 'open',
      ERRORS: 'fillInErrors',
      SERVICE_LOCATOR: {
        NEAR_ME: 'nearMe',
        SEARCH: 'search',
        VISIT_WEBSITE: 'visitWebsite',
        GOOGLE_MAPS: 'googleMaps',
      },
      LINK_TO_PDP: 'linkToPdp',
      ARROW: 'arrow',
      TAB_LOADED: 'tabLoaded',
      REQUEST_INVOICE: 'step5_payment_method–request_invoice',
      SIGN_UP_PURCHASE_TYP: 'signUp- purchaseTyp',
      KENWOOD: {
        CONFIGURATOR: {
          STEP_0: 'step_0',
          STEP_1: 'step_1-choose',
          STEP_2: 'step_2-customize',
          STEP_3: 'step_3-summary',
          STEP_4: 'step_4-add_to_basket',
          PROMO_ALERT: 'promo_alert',
          TWIST_SYSTEM: 'adapter_twist_system',
          ADAPTER_ALERT: 'adapter_alert',
          ADAPTER_INFO: 'adapter_info',
        },
      },
    },
    CART: {
      ADD: 'addToCart',
      REMOVE: 'removeFromCart',
      ADD_GA4: 'GA4-addToCart',
      REMOVE_GA4: 'GA4-remove_from_cart',
      SAVE_CART: 'step1_cart-savecart',
    },
    CHECKOUT: 'checkout',
    PURCHASE_GA4: 'GA4-purchase',
    PURCHASE_TYP: 'purchaseTyp',
    ERRORS: 'fillInErrors',
    CHECKOUT_ACCESS: 'checkoutAccess',
    PRODUCT: {
      REDUCED: 'reduced',
      FULL_PRICE: 'fullprice',
      OUT_STOCK: 'out-of-stock',
      IN_STOCK: 'in-stock',
    },
    COMPARISON_TOOL: 'comparisonTool',
    PRODUCT_CLICK: 'productClick',
    LISTING_IMPRESSION: 'productImpressions',
    LISTING_IMPRESSION_LOAD_MORE: 'productImpressionsLoadMore',
    LISTING_IMPRESSION_SCROLL: 'productImpressionsScroll',
    LOAD_CHECKOUT_ERRORS: 'loadCheckoutErrors',
    PRODUCT_REGISTRATION: 'productRegistration',
    GENERIC: 'generic',
  },
  COFFEE_LOUNGE: {
    EVENT: 'generic',
    EVENT_CATEGORY: 'engagement',
    EVENT_ACTION: 'link to Coffee Lounge',
    EVENT_LABEL_MENU: 'menu',
    EVENT_LABEL_NEWS: 'news and events',
    EVENT_LABEL_CHANNEL: 'coffee channel',
    EVENT_LABEL_CATEGORY: 'category page',
  },
  FILTERS: {
    FILTER: 'filter',
    EVENT_CATEGORY: 'engagement',
    FILTER_APPLY: 'filter_apply',
    FILTER_REMOVE: 'filter_remove',
    FILTER_REMOVE_ALL: 'remove_all_filters',
    ORDER_BY: 'order_by',
  },
  LIST_NAME: {
    WISHLIST: 'wishlist',
  },
};
