/* eslint-disable import/prefer-default-export */
import GTM from '@/utils/Controllers/GTM/Constants';
import { push } from '@/utils/Controllers/GTM/Utils';

export const pushGtmWishlistToggle = (
  isIn,
  productCode,
  productName,
  productCategory,
) => {
  push({
    event: isIn ? GTM.EVENT.WISHLIST.IN : GTM.EVENT.WISHLIST.OUT,
    ecommerce: {
      items: [
        {
          item_id: productCode,
          item_name: productName,
          item_category: productCategory,
        },
      ],
    },
  });
};
