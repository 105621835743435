import GTM from '@/utils/Controllers/GTM/Constants';
import { push, getProductsGtmInfo } from '@/utils/Controllers/GTM/Utils';

export const pushGtmCartOperation = ({ isAdd, payload }) => {
  push({
    event: isAdd ? GTM.EVENT.CART.ADD : GTM.EVENT.CART.REMOVE,
    ecommerce: {
      currencyCode: DLG.config.currencyIsoCode,
      ...payload,
    },
  });
};

export const pushGtmCartOperationGA4 = ({ isAdd, bundle, items }) => {
  items.forEach(item => {
    item.item_name = item.name;
    item.item_id = item.id;
    item.item_category = item.category;
    item.item_variant = item.variant;
    item.fullprice = item.dimension2;
    item.rating_points = item.dimension3;
    item.erp_category = item.dimension4;
    item.prdha_category = item.dimension5;

    [
      'dimension2',
      'dimension3',
      'dimension4',
      'dimension5',
      'name',
      'id',
      'category',
      'variant',
    ].forEach(k => {
      delete item[k];
    });
  });

  const itemsFiltered = { items };
  push({
    event: isAdd ? GTM.EVENT.CART.ADD_GA4 : GTM.EVENT.CART.REMOVE_GA4,
    ecommerce: {
      ...bundle,
      ...itemsFiltered,
    },
  });
};

export const callGtmAddToCart = entry => {
  const quantity = 1;
  const productInfo = getProductsGtmInfo([entry], quantity);
  const gtmProductInfo = { add: { products: productInfo } };
  pushGtmCartOperation({ isAdd: true, payload: gtmProductInfo });
  const productInfoGA4 = getProductsGtmInfo([entry], quantity);
  const bundle = { bundle: false };
  pushGtmCartOperationGA4({ isAdd: true, bundle, items: productInfoGA4 });
};

export const callGtmRemoveFromCart = ({ entry, quantity = 1 }) => {
  const productInfo = getProductsGtmInfo([entry], quantity);
  const gtmProductInfo = { remove: { products: productInfo } };
  pushGtmCartOperation({ isAdd: false, payload: gtmProductInfo });
  const productInfoGA4 = getProductsGtmInfo([entry], quantity);
  const bundle = { bundle: false };
  pushGtmCartOperationGA4({ isAdd: false, bundle, items: productInfoGA4 });
};

export const callGtmAddBundleToCart = (templateId, entries) => {
  const quantity = 1;
  const productInfo = getProductsGtmInfo(entries, quantity);
  const gtmProductInfo = { add: { products: productInfo } };
  pushGtmCartOperation({ isAdd: true, payload: gtmProductInfo });
  const productInfoGA4 = getProductsGtmInfo(entries, quantity);
  const bundle = { bundle_id: templateId, bundle_type: 'bundle', bundle: true };
  pushGtmCartOperationGA4({ isAdd: true, bundle, items: productInfoGA4 });
};

export const pushGtmCartSaveCart = () => {
  push({
    event: GTM.EVENT.GENERIC,
    eventCategory: GTM.EVENT.CATEGORY.ECOMMERCE,
    eventAction: GTM.EVENT.CHECKOUT,
    eventLabel: GTM.EVENT.CART.SAVE_CART,
  });
};
